import React from 'react';
import "./why.css"
import { CHECK, CLOSE, ENVIRONMENT, EXCELLENCE_IN_CARE, TEAM } from "./icons"
const Why = () => {
    return <React.Fragment>
        <section>
            <div className="container-fluid why py-5 px-4">
                <div className="col col-lg-11 col-xl-10 mx-lg-auto text-center text-md-start pt-5 text-div">
                    <h2>
                        Why Choose Vermel Hospice, Inc
                    </h2>
                    <p className='col-lg-8'>
                        Our unwavering commitment to top-tier care, active community involvement, and compassionate support sets us apart, ensuring comfort and understanding during life's challenging moments.
                    </p>
                </div>
                <div className="col col-lg-11 col-xl-10 mx-lg-auto">
                    <div className="row justify-content-around card-div">
                        <div className="col-12 col-md-4">
                            <div className="my-card ">
                                <div className='my-2' >
                                    <EXCELLENCE_IN_CARE height='3.5em' color='rgb(255, 213, 0)' />

                                </div>
                                <h2 className='my-2'>Excellence in Care</h2>
                                <p className='my-2'>Vermel Hospice, Inc sets the standard for hospice excellence. With a highly qualified team and continuous training, we prioritize the highest degree of service, ensuring comfort, symptom relief, and emotional support for both patients and families.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="my-card ">
                                <div className='my-2' style={{ maxWidth: "60px" }}>
                                    <ENVIRONMENT height='3.5em' color='rgb(255, 213, 0)' />

                                </div>
                                <h2 className='my-2'>Community Engagement</h2>
                                <p className='my-2'>Beyond care, Vermel Hospice, Inc actively participates in community events and fundraisers, creating meaningful connections. We believe in fostering a supportive environment that extends beyond our services.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="my-card ">
                                <div className='my-2' style={{ maxWidth: "60px" }}>
                                    <TEAM height='3.5em' color='rgb(255, 213, 0)' />
                                    {/* <img src="assets/CARE-yellow.png" alt="certificate" srcSet="" className='img-fluid' /> */}
                                </div>
                                <h2 className='my-2'>Compassion at Every Step</h2>
                                <p className='my-2'>When you choose Vermel Hospice, Inc, you choose compassionate support. Our dedicated team, committed to ethical responsibility, provides comfort and understanding during life's challenging moments, ensuring the well-being of our community.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='why-detail pb-3'>
            <div className="container-fluid px-4 px-md-0 px-xl-4">
                <div className="row justify-content-center col-lg-11 col-xl-10 mx-auto">
                    <div className="col-12 col-md-6 col-xl-5">
                        <div className="row justify-content-center align-items-end">
                            <div className="col-6 text-end">
                                <img src="assets/images/photo_2024-01-05_00-03-11.jpg" alt="" srcSet="" className='img-fluid my-2' style={{ borderRadius: "15px", maxWidth: "80%" }} />
                                <img src="assets/images/photo_2024-01-03_11-54-19.jpg" alt="" srcSet="" className='img-fluid my-2' style={{ borderRadius: "15px" }} />
                            </div>
                            <div className="col-6 text-start">
                                <img src="assets/images/photo_2024-01-03_11-52-00.jpg" alt="" srcSet="" className='img-fluid my-2' style={{ borderRadius: "15px" }} />
                                <img src="assets/images/photo_2024-01-04_23-57-00.jpg" alt="" srcSet="" className='img-fluid my-2' style={{ borderRadius: "15px" }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-5">
                        <div className="row">
                            <div className="col-12 more-about-div text-center text-md-start">
                                <h2>Know More About Our Care Team</h2>
                                <p>Unlock the details of Vermel Hospice, Inc. care team services, designed to elevate the standard of care and comfort for our patients.</p>
                            </div>
                            <div className="col-12 detail">
                                <div className="row">
                                    <div className="col-auto">
                                        <CHECK height='1.5em' color='green' />
                                    </div>
                                    <div className="col">
                                        <p>Expertly Trained Nurses: Our care team team undergoes rigorous training, ensuring expertise in managing discomfort, providing symptom relief, and offering emotional support.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto ">
                                        <CHECK height='1.5em' color='green' />
                                    </div>
                                    <div className="col">
                                        <p>Personalized Care Plans: Tailored to individual needs, our care team services include personalized care plans, addressing unique challenges and enhancing overall well-being.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto ">
                                        <CHECK height='1.5em' color='green' />
                                    </div>
                                    <div className="col">
                                        <p>Continuous Availability: With 24/7 on-call support, our care team team is dedicated to providing unwavering care whenever it's needed, ensuring peace of mind for patients and their families.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto ">
                                        <CHECK height='1.5em' color='green' />
                                    </div>
                                    <div className="col">
                                        <p>Holistic Approach: Beyond medical care, our care team services adopt a holistic approach, considering emotional, spiritual, and social aspects to provide comprehensive support.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
}

export default Why;