import ContactUs from "./contact-us";
import Footer from "./footer";
import "./contact-us.css";
const ContactUsPage = () => {
    return (
        <div className="container-fluid contact-us-page px-0">
            <div className="col-12 baner text-center px-3" >
                <div className="baner-overlay"></div>
                <div className="baner-content">
                    <h1>Contact Us</h1>
                    <p className="col-xl-5 mx-auto">Connect with Vermel Hospice, Inc. and let us guide you through compassionate care, personalized services, and a supportive community during life's challenging times.</p>
                </div>
            </div>
            <div className="col-12">
                <ContactUs />
                <Footer />
            </div>
        </div>
    );
}

export default ContactUsPage