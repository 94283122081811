import React from 'react';
import "./about-us.css";
import AboutUs from './about-us';
import Footer from './footer';
import Why from './why';
const AboutUsPage = () => {
    return <div className="container-fluid about-us-page px-0">
        <div className="col-12 baner text-center px-3" >
            <div className="baner-overlay"></div>
            <div className="baner-content">
                <h1>About Us</h1>
                <p className="col-xl-5 mx-auto">Explore the heart of Vermel Hospice, Inc. where our commitment to excellence, community engagement, and ethical responsibility defines our holistic approach to hospice care.</p>
            </div>
        </div>
        <div className="col-12">
            <Why />
            <Footer />
        </div>
    </div>
};

export default AboutUsPage;