import React, { useRef } from 'react';
import "./contact-us.css";
import { CLOSE, INSTAGRAM, LOCATION, MAIL, PHONE } from './icons';
import { toast } from 'react-toastify';
const ContactUs = () => {
    const nameref = useRef(null);
    const emailref = useRef(null);
    const subjectref = useRef(null);
    const messageref = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: nameref.current.value,
            email: emailref.current.value,
            subject: subjectref.current.value,
            message: messageref.current.value
        };

        // validate
        if (!data.email.includes("@") || !data.email.includes(".") || !data.email.length > 0) {
            toast.error(`🦄 Invalid Email`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        // validate name
        if (!data.name.length > 0) {
            toast.error(`🦄 Invalid Name`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        // validate subject
        if (!data.subject.length > 0) {
            toast.error(`🦄 Invalid Subject`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        // validate message
        if (!data.message.length > 0) {
            toast.error(`🦄 Invalid Message`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        // validate subject
        if (!data.message.length > 0) {
            toast.error(`🦄 Invalid Subject`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        // send post request to server /contact-us
        const url = 'https://vermel-server.onrender.com/contact-us';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(function (response) {
            if (response.ok) {
                toast.success('Thank you for your message. It has been sent.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(`🦄 ${response.statusText}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).then(function
            (data) {
            // handle response data
        }).catch(function (error) {
            // handle error
            toast.error(`🦄 ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });

    }
    return (
        <div className="container-fluid contact-us">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-5 col-xl-4 py-5 text-center my-lg-auto ">
                    <div className="col ">
                        <div className="heading row justify-content-between mx-auto text-xl-start" >
                            <h2>Get In Touch</h2>
                            <p >For compassionate guidance, personalized care, and support during life's critical moments, reach out to Vermel Hospice, Inc. Our dedicated team is here to assist you every step of the way.</p>

                        </div>
                        <div className="row justify-content-between mx-auto get-in-touch" >
                            <div className="col-4 col-md-4 text-center">
                                <PHONE color="rgb(255, 213, 0)" height="2em" />
                                <h3>Phone</h3>
                                <a href="tel:(818) 293-3616" className="btn py-0 my-0 px-0 font-weight-bold p">(818) 293-3616 </a>
                            </div>
                            <div className="col-4 col-md-4 text-center">
                                <LOCATION color="rgb(255, 213, 0)" height="2em" />
                                <h3>Address</h3>
                                <a style={{ maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} href="https://www.google.com/maps/dir//8119+Foothill+Blvd+%236+Sunland,+CA+91040+USA/@34.2599172,-118.3097752,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80c293576a9c1d8d:0xd05f1084a449e757!2m2!1d-118.3097752!2d34.2599172?entry=ttu" target='_blank' className="btn py-0 my-0">California, USA</a>
                            </div>
                            <div className="col-4 col-md-4 text-center">
                                <MAIL color="rgb(255, 213, 0)" height="2em" />
                                <h3>Email</h3>
                                <a style={{ maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} href="mailto:vermelhospicecare@gmail.com" className="btn py-0 my-0">vermelhospicecare@gmail.com</a>
                            </div>
                        </div>
                        {/* <div className="row justify-content-between mt-2">
                            <div className="col-auto text-center">
                                <LOCATION color="rgb(255, 213, 0)" height="2em" />
                                <h3>Address</h3>
                                <a style={{ maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} href="https://www.google.com/maps/dir//8119+Foothill+Blvd+%236+Sunland,+CA+91040+USA/@34.2599172,-118.3097752,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80c293576a9c1d8d:0xd05f1084a449e757!2m2!1d-118.3097752!2d34.2599172?entry=ttu" target='_blank' className="btn py-0 my-0">California, USA</a>
                            </div>
                            <div className="col-auto mx-xl-4">
                                <INSTAGRAM color="rgb(217, 26, 255)" height="2em" />
                                <h3>Instagram</h3>
                                <a style={{ maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} href="https://www.instagram.com/vermelhospiceinc" target='_blank' className="btn py-0 my-0">@vermelhospiceinc</a>
                            </div>
                        </div> */}
                        <div className="row justify-content-between py-5">
                            <table
                                cellPadding={0}
                                cellSpacing={0}
                                className="es-table-not-adapt es-social"
                                style={{
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    borderCollapse: "collapse",
                                    borderSpacing: 0
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td
                                            align="center"
                                            valign="top"
                                            style={{ padding: 0, margin: 0, paddingRight: 15 }}
                                        >
                                            <a
                                                target="_blank"
                                                href="https://www.facebook.com/profile.php?id=61555290358733"
                                                style={{
                                                    WebkitTextSizeAdjust: "none",
                                                    msTextSizeAdjust: "none",
                                                    msoLineHeightRule: "exactly",
                                                    textDecoration: "underline",
                                                    color: "#DCE8F3",
                                                    fontSize: 12
                                                }}
                                            >
                                                <img
                                                    src="https://fbdlqeo.stripocdn.email/content/assets/img/social-icons/circle-colored/facebook-circle-colored.png"
                                                    alt="Fb"
                                                    title="Facebook"
                                                    width={24}
                                                    style={{
                                                        display: "block",
                                                        border: 0,
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        msInterpolationMode: "bicubic"
                                                    }}
                                                />
                                            </a>
                                        </td>
                                        <td
                                            align="center"
                                            valign="top"
                                            style={{ padding: 0, margin: 0, paddingRight: 15 }}
                                        >
                                            <a
                                                target="_blank"
                                                href="https://www.instagram.com/vermelhospiceinc?igsh=Zm83bDJmemlkendk&utm_source=qr"
                                                style={{
                                                    WebkitTextSizeAdjust: "none",
                                                    msTextSizeAdjust: "none",
                                                    msoLineHeightRule: "exactly",
                                                    textDecoration: "underline",
                                                    color: "#DCE8F3",
                                                    fontSize: 12
                                                }}
                                            >
                                                <img
                                                    src="https://fbdlqeo.stripocdn.email/content/assets/img/social-icons/circle-colored/instagram-circle-colored.png"
                                                    alt="Ig"
                                                    title="Instagram"
                                                    width={24}
                                                    style={{
                                                        display: "block",
                                                        border: 0,
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        msInterpolationMode: "bicubic"
                                                    }}
                                                />
                                            </a>
                                        </td>
                                        <td
                                            align="center"
                                            valign="top"
                                            style={{ padding: 0, margin: 0, paddingRight: 15 }}
                                        >
                                            <a
                                                target="_blank"
                                                href="https://yelp.to/ojDDNwHCjo"
                                                style={{
                                                    WebkitTextSizeAdjust: "none",
                                                    msTextSizeAdjust: "none",
                                                    msoLineHeightRule: "exactly",
                                                    textDecoration: "underline",
                                                    color: "#DCE8F3",
                                                    fontSize: 12
                                                }}
                                            >
                                                <img
                                                    src="https://vermel-inc-v2.netlify.app/assets/yelp.ico"
                                                    alt="Yp"
                                                    title="Yelp"
                                                    width={24}
                                                    style={{
                                                        display: "block",
                                                        border: 0,
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        msInterpolationMode: "bicubic"
                                                    }}
                                                />
                                            </a>
                                        </td>
                                        <td
                                            align="center"
                                            valign="top"
                                            style={{ padding: 0, margin: 0, paddingRight: 15 }}
                                        >
                                            <a
                                                target="_blank"
                                                href="https://www.linkedin.com/in/vermel-hospice-inc-7374652a8?trk=contact-info"
                                                style={{
                                                    WebkitTextSizeAdjust: "none",
                                                    msTextSizeAdjust: "none",
                                                    msoLineHeightRule: "exactly",
                                                    textDecoration: "underline",
                                                    color: "#DCE8F3",
                                                    fontSize: 12
                                                }}
                                            >
                                                <img
                                                    src="https://fbdlqeo.stripocdn.email/content/assets/img/social-icons/circle-colored/linkedin-circle-colored.png"
                                                    alt="In"
                                                    title="Linkedin"
                                                    width={24}
                                                    style={{
                                                        display: "block",
                                                        border: 0,
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        msInterpolationMode: "bicubic"
                                                    }}
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 pt-5  text-center mt-lg-5">
                    <div className="col contact-us-form" >
                        <h3>Send us a message</h3>
                        <form>
                            <div className="container text-start">
                                <div className="row ">
                                    <div className="col-12">
                                        <div className="form-group ">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" ref={nameref} placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" ref={emailref} placeholder="Email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="subject">Subject</label>
                                            <input type="text" className="form-control" ref={subjectref} placeholder="Subject" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea className="form-control" rows="5" ref={messageref} placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="py-4 col-lg-11 col-xl-9 mx-auto ">
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=8119%20Foothill%20Blvd.,%20Ste.%206%20Sunland,%20CA%2091040&t=m&z=15&output=embed&iwloc=near"
                    title="Sunland, CA 91040, USA"
                    aria-label="Sunland, CA 91040, USA"
                    width="100%"
                    height="300"
                />
            </div>
        </div>
    );
}

export default ContactUs