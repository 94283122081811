import React, { useRef } from "react";
import "./appointment.css";
import { toast } from 'react-toastify';
// import { sendMail } from "../utility/sendEmail";
const Appointment = () => {
    const emailref = useRef(null);
    const nameref = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        // send post request using fetch
        const url = 'https://vermel-server.onrender.com/appointment';
        const data = {
            email: emailref.current.value,
            name: nameref.current.value
        };
        // validate email
        if (!data.email.includes("@") || !data.email.includes(".") || !data.email.length > 0) {
            toast.error(`🦄 Invalid Email`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        if (!data.name.length > 0) {
            toast.error(`🦄 Invalid Name`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return;
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                // handle response data
                toast.success('Thank you for your message. It has been sent.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch(error => {
                // handle error
                toast.error(`🦄 ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    return <div className="container-fluid appointment px-xl-5">
        <div className="col col-lg-11 mx-lg-auto">
            <div className="row justify-content-around align-items-center py-5 px-xl-5">
                <div className="col-10 col-md align-self-md-center">
                    <h2 style={{ fontSize: "1.5em" }}>Make Appointment</h2>
                    <p>If you or a loved one requires compassionate hospice care, we are here to support you. Please reach out to us for more information and guidance through this important journey.</p>
                </div>
                <div className="col-10 col-md align-self-md-center">
                    <label htmlFor="email" className="col-form-label">Email</label>
                    <input type="email" className="form-control" name="email" id="email" ref={emailref} placeholder="Email" aria-label="Email" />
                </div>
                <div className="col-10 col-md align-self-md-center ">
                    <label htmlFor="name" className="col-form-label">Name</label>
                    <input type="text" className="form-control" name="name" id="name" ref={nameref} placeholder="Name" aria-label="Name" />
                </div>
                <div className="col-10 col-md align-self-md-center">
                    <label htmlFor="submit" className="col-form-label" style={{ minWidth: "10px", color: "transparent" }}>|</label>
                    <button type="submit" className="btn form-control btn-primary" onClick={handleSubmit}>SUBMIT</button>
                </div>
            </div>

        </div>
    </div>
}

export default Appointment;