import React from "react";
import "./explore.css"

const Explore = () => {
    return <div className="container-fluid explore prevent-select">
        <div className="row justify-content-center ">
            <div className="col-12 col-md-6 px-auto image-div mx-0 px-0">
                <img src="assets/photo_2024-01-03_11-56-08-1-removebg.png" alt="" srcSet="" className="img-fluid px-0" />
            </div>
            <div className="col-12 col-md-6 px-0 align-self-center align-self-md-end align-self-lg-center ">
                <div className="container text-center text-md-start py-5 px-md-3 px-lg-5 mb-3 mb-lg-0 ">
                    <div className="row justify-content-center justify-content-md-start align-items-center px-md-2">
                        <div className="bg-warning col-auto vermel-div">
                            <h1>Vermel Hospice, Inc</h1>
                        </div>
                        <div className="col-auto here-div">
                            <h1>Here</h1>
                        </div>
                    </div>
                    <div className="col-auto for-your-care">
                        <h1>
                            For Your Care
                        </h1>
                    </div>
                    <div className="col-auto pt-2 discription">
                        <p>
                            Discover unparalleled hospice care at Vermel Hospice, Inc. We prioritize excellence in every service, boasting highly qualified and continually trained staff. Committed to ethical responsibility, we protect our community from fraud and abuse. Our mission is to provide top-tier care, support, and comfort during life's toughest moments. Join us and experience the Vermel Hospice, Inc difference, where excellence meets compassion.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Explore;