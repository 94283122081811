import React from "react";
import Explore from "./explore";
import Appointment from "./appointment";
import Why from "./why";
import Service from "./services";
import Careers from "./careers";
import Blogs from "./blogs";
import Footer from "./footer";

const Home = () => {
    return <>
        <Explore />
        <Appointment />
        <Why />
        <Service />
        {/* <Careers /> */}
        <Blogs />
        <Footer />
    </>
}

export default Home;