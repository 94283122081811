import React from 'react';
import "./blogs.css";
import { Link } from 'react-router-dom';
const blogs = [
    {
        'id': '64d22f6448b5472671440ad6',
        'title': 'Navigating End-of-Life Conversations: A Guide for Families',
        'image': '/assets/images/photo_2024-01-03_11-56-43.jpg',
        'description': `Initiating conversations about end-of-life decisions is a profound yet challenging necessity. Our guide, "Navigating End-of-Life Conversations: A Guide for Families," is a comprehensive resource crafted to provide families with the tools to approach these discussions with empathy and understanding.

        In the first section, we delve into effective communication strategies tailored for these sensitive moments. From setting the right atmosphere to choosing the appropriate words, our guide offers insights into creating an environment conducive to open and honest dialogues. Understanding that every family dynamic is unique, we provide adaptable approaches to ensure these crucial conversations are approached with respect and sensitivity.
        
        The second part of the guide addresses common concerns that often arise during end-of-life discussions. Whether it's clarifying medical decisions, addressing emotional aspects, or navigating family dynamics, we provide guidance on how to navigate these complexities. By offering practical advice and real-life scenarios, our aim is to empower families to proactively engage in these conversations, fostering a shared understanding of their loved ones' wishes.
        
        The final section of the guide emphasizes the importance of creating a supportive environment for open dialogues. It discusses resources available to families, including support groups and counseling services, to help navigate the emotional aspects that often accompany end-of-life discussions. By equipping families with these tools, our guide aims to facilitate conversations that honor the wishes of their loved ones, fostering a sense of shared understanding and unity during challenging times.`
    },
    {
        'id': '64d22f6448b5472671440ad7',
        'title': 'The Role of Hospice Aides: Enhancing Comfort and Dignity',
        'image': '/assets/images/photo_2024-01-03_11-55-49.jpg',
        'description': `The journey towards the end of life requires a compassionate and supportive care team, and hospice aides play a pivotal role in enhancing the comfort and dignity of patients. In this exploration of "The Role of Hospice Aides," we delve into the multifaceted responsibilities that hospice aides undertake to ensure patients experience the highest quality of care during their final moments.

        The first section provides an in-depth look at the specific duties performed by hospice aides, ranging from assisting with personal hygiene to offering emotional support. We highlight the importance of these tasks in creating a dignified and comfortable environment for patients, allowing them to focus on what matters most to them during this sensitive time.
        
        In the second part, we explore the profound impact hospice aides have on patients' lives. By providing personalized and empathetic care, hospice aides contribute significantly to the overall well-being of patients and their families. Real-life stories and testimonials underscore the compassionate connections formed between hospice aides and those under their care, illustrating the invaluable support they provide beyond medical assistance.
        
        The final section delves into the training and qualifications that set hospice aides apart. Rigorous training ensures that hospice aides possess the necessary skills to navigate the complexities of end-of-life care. By examining the commitment to continuous professional development, this blog emphasizes how hospice aides are equipped with the expertise required to meet the unique needs of each patient, promoting a holistic and dignified approach to end-of-life care.`
    },
    {
        'id': '64d22f6448b5472671440ad8',
        'title': 'Holistic Care in Hospice: Beyond Medical Attention',
        'image': '/assets/images/photo_2024-01-03_11-54-57.jpg',
        'description': `"Holistic Care in Hospice: Beyond Medical Attention" delves into the comprehensive approach that extends beyond traditional medical practices to address the emotional, spiritual, and social aspects of end-of-life care. In the initial segment, we explore the integration of these elements into the care framework, highlighting the positive impact on the overall well-being of patients and their families. By understanding the broader spectrum of human needs, this blog underscores how holistic care contributes to a supportive environment during challenging times.

        The second part emphasizes real-life examples and testimonials, showcasing the transformative impact of holistic care on patients. Through stories of individuals who have experienced this approach, we illustrate how addressing emotional and spiritual aspects enhances the quality of life, fostering a sense of comfort and connection. By recognizing the human experience in its entirety, this blog aims to inspire a shift towards a more holistic and person-centered approach in hospice care.
        
        In the final section, we explore the practical implementation of holistic care, including the collaboration of various professionals such as social workers, spiritual counselors, and volunteers. By highlighting their roles and contributions, we illustrate how these interdisciplinary efforts create a comprehensive support system for patients and their families. This blog serves as a guide for both healthcare professionals and families seeking to understand and implement holistic care practices in the hospice setting.`
    },
    {
        'id': '64d22f6448b5472671440ad9',
        'title': 'Supporting Grieving Hearts: The Importance of Bereavement Services',
        'image': '/assets/291ca109-8103-469e-a444-f14f81b93dd1.png',
        'description': `"Supporting Grieving Hearts: The Importance of Bereavement Services" sheds light on the crucial role of bereavement services in the hospice care continuum. In the first section, we delve into the emotional aspects of grief, exploring the unique challenges faced by individuals and families before and after the loss of a loved one. By providing insights into the grieving process, this blog aims to foster understanding and empathy for those navigating this profound journey.

        The second part introduces bereavement services as a vital component of holistic hospice care. We examine the supportive counseling offered to both patients and their families, highlighting the tailored approaches to address individual needs. By sharing stories of individuals who have found solace through bereavement services, we illustrate the transformative impact of compassionate support during the grieving process.
        
        The final section emphasizes the extended care provided by bereavement services, reaching up to one year after a patient's passing. We explore coping strategies, community resources, and the importance of a caring community during the grieving process. This blog serves as a comprehensive resource for individuals and families seeking guidance on navigating grief, emphasizing the significance of ongoing support and care.
        
        `
    }
]
const Blogs = () => {
    return <div className="container-fluid blogs py-5">
        <div className="col-12 col-lg-11 col-xl-10 mx-auto text-center blog-header text-md-start mt-5">
            <h2>Blog & Article</h2>
            <p className='col-lg-5'>Uncover a world of knowledge and inspiration in our Blog & Article section, where we share insights on hospice care, well-being, and the art of navigating life's profound moments.</p>
        </div>
        <div className="col-12 col-lg-11 col-xl-10 mx-auto">
            <div className="row justify-content-around justify-content-md-start">
                {
                    blogs.map((blog, index) => {
                        return <div className="col-12 col-md-4 col-xl-3 my-3" key={index}  >
                            <div className="blog-card card">
                                <div className="card-img-top blog-image">
                                    <img src={blog.image} alt="" srcSet="" className='img-fluid' />
                                </div>
                                <div className="card-body blog-detail">
                                    <h5 className="card-title">{blog.title.substring(0, blog.title.indexOf(':'))}</h5>
                                    <p className='card-text'>{blog.description.substring(0, 100) + '...'}</p>
                                    <Link to={`/blogs/${blog.id}`} className="btn btn-primary">Read More</Link>
                                    {/* <button className='btn btn-primary'>Read More</button> */}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
};

export default Blogs;