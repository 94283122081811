
import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home';
import ServicePage from './components/service-page';
import Header from './components/header';
import CareerPage from './components/career-page';
import AboutUsPage from './components/about-us-page';
import ContactUsPage from './components/contact-us-page';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogsPage from './components/blogs-page';
import BlogDetailComponent from './components/blog-details';

// import About from './component/about-us';
// import Services from './component/services';
// import ContactUs from './component/contact-us';



function App() {
  return (
    <React.Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blogs/:id" element={<BlogDetailComponent />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
