import "./footer.css";
import { CLOSE, LOCATION, MAIL, PHONE } from "./icons";
const Footer = () => {
    return <div className="footer">
        <div className="footer-overlay"></div>
        <div className="container-fluid">
            <div className="col-12 col-lg-11 col-xl-10 mx-auto">
                <div className="row justify-content-around justify-content-md-start">
                    <div className="col-12 col-md my-3">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-auto  align-self-center align-items-center">
                                <LOCATION color="#FFD500" height="1.7rem" />
                            </div>
                            <div className="col-auto px-0 align-self-center align-items-center">
                                <a style={{ maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} href="https://www.google.com/maps/dir//8119+Foothill+Blvd+%236+Sunland,+CA+91040+USA/@34.2599172,-118.3097752,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80c293576a9c1d8d:0xd05f1084a449e757!2m2!1d-118.3097752!2d34.2599172?entry=ttu" target='_blank' className="btn py-0 my-0">8119 Foothill Blvd., Ste. 6 Sunland, CA 91040</a>
                                <p className="my-0 "></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md my-3">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-auto  align-self-center align-items-center">
                                <PHONE color="#FFD500" height="1.7rem" />
                            </div>
                            <div className="col-auto px-0 align-self-center align-items-center">
                                <a href="tel:(818) 293-3616" className="btn py-0 my-0">(818) 293-3616 </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md my-3">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-auto  align-self-center align-items-center">
                                <MAIL color="#FFD500" height="1.7rem" />
                            </div>
                            <div className="col-auto px-0 align-self-center align-items-center">
                                <a href="mailto:vermelhospicecare@gmail.com" className="btn py-0 my-0">vermelhospicecare@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ height: "2px" }} />
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <p className="my-0">© 2023. Vermel Hospice, Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Footer;