import React from 'react';
import "./services.css";
import Service from './services';
import Footer from './footer';
const ServicePage = () => {
    return <div className="container-fluid service-page px-0">
        <div className="col-12 baner text-center px-3" >
            <div className="baner-overlay"></div>
            <div className="baner-content">
                <h1>Services</h1>
                <p className="col-xl-5 mx-auto">Elevate your well-being with Vermel Hospice, Inc. comprehensive services, ensuring compassionate and tailored care for you and your loved ones.</p>
            </div>
        </div>
        <div className="col-12">
            <Service />
            <Footer />
        </div>
    </div>
}

export default ServicePage;  