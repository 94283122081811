import React from 'react';
import "./services.css"
const Service = () => {
    return <div className="container-fluid services py-5 px-3">
        <div className="col-12 col-lg-11 col-xl-10 mx-auto text-center service-header text-md-start mt-5">
            <h2>Our Service</h2>
            <p className='col-lg-5'>Explore the extensive range of services provided by Vermel Hospice, Inc. recognizing you and your family as integral members of our collaborative team.</p>
        </div>
        <div className="col-12 col-lg-11 col-xl-10 mx-auto">
            <div className="row justify-content-around justify-content-md-start">
                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-56-29.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>Medical Professionals</h2>
                            <p>Our dedicated team includes hospice doctors, nurses, and your chosen medical professionals, ensuring comprehensive care, symptom relief, and emotional support throughout your journey.</p>
                        </div>
                    </div>
                </div>
                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-54-18-1.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>Personalized Care</h2>
                            <p> Hospice aides, homemakers, and volunteers offer personalized care, assisting with personal hygiene, household tasks, providing companionship, and more.</p>
                        </div>
                    </div>
                </div>
                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-51-29.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>Comfort at Home</h2>
                            <p>Medical equipment and supplies are provided to enable patients to stay comfortably at home, addressing symptoms and needs related to the terminal illness.</p>
                        </div>
                    </div>
                </div>
                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-50-54.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>Therapists and Specialists</h2>
                            <p>Physical and occupational therapists, speech-language pathologists, and dieticians collaborate to enhance safety, manage symptoms, and maintain optimal functioning and independence.</p>
                        </div>
                    </div>
                </div>

                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-52-16.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>Pharmacy Services</h2>
                            <p>In collaboration with pharmacies, we provide medications around the clock, addressing terminal illness, pain management, and symptom relief. 24 hr pharmacy door to door service.</p>
                        </div>
                    </div>
                </div>

                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-51-45.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>Emotional and Spiritual Support</h2>
                            <p>Our social workers, spiritual counselors, and bereavement services assess emotional factors, offer counseling, conduct prayer and rituals, and provide supportive care prior to and up to one year after a patient's passing.</p>
                        </div>
                    </div>
                </div>
                <div className="service-card col-12 col-md-4 col-xl-3 my-3">
                    <div className="service-image">
                        <img src="/assets/images/photo_2024-01-03_11-55-13.jpg" alt="" srcSet="" className='img-fluid' />
                    </div>
                    <div className="service-detail px-3">
                        <div className="container  text-center">
                            <h2>24/7 On-Call Support</h2>
                            <p>A hospice nurse and doctor are available 24/7, ensuring continuous support and care for you and your family when it's needed most.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Service;