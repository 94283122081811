import Blogs from "./blogs";
import Footer from "./footer";

const BlogsPage = () => {
    return <div className="container-fluid about-us-page px-0">
        <div className="col-12 baner text-center px-3" >
            <div className="baner-overlay"></div>
            <div className="baner-content">
                <h1>Blogs</h1>
                <p className="col-xl-5 mx-auto">Dive into a wealth of knowledge and compassionate insights with our thoughtfully curated blogs. Explore the diverse topics surrounding hospice care, well-being, and meaningful moments, as we share valuable perspectives to inspire and support you on your journey.</p>
            </div>
        </div>
        <div className="col-12">
            <Blogs />
            <Footer />
        </div>
    </div>
};

export default BlogsPage;